import React from "react"
import { Styled } from "theme-ui"

import Layout from "@layouts/form"
import LogoutButton from "@buttons/LogoutButton"
import UserProfile from "@forms/UserProfile"

export default () => {
  return (
    <Layout title="Profil użytkownika">
      <Styled.h4
        style={{
          color: `#000`,
          fontSize: `22px`,
          lineHeight: `25px`,
          textAlign: `center`,
          flex: '1 0 100%'
        }}
      >
        Profil użytkownika
      </Styled.h4>
      <Styled.p
      style={{
        letterSpacing: `0px`, 
        color: `#7D7D7D`,
        fontSize: `13px`,
        lineHeight: `25px`,
        fontFamily: `Poppins`,
        fontWeight: 400,
        textAlign: `center`,
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      Zarządzaj ustawieniami swojego konta
    </Styled.p>
      <UserProfile/>
      <LogoutButton />
    </Layout>
  )
}
