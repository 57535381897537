import React from "react"
import { Formik } from "formik"
import { useIdentityContext } from "react-netlify-identity"
import { object, string } from "yup"
import { errorMessages } from "@const"
import UserProfile from "./UserProfile"

export default () => {
  const {updateUser, user } = useIdentityContext();
  const formikProps = {
    initialValues: {
      email: (user && user.email) || "",
      password: "",
      firstName: (user && user.user_metadata.firstName) || "",
      lastName: (user && user.user_metadata.lastName) || ""
    },
    onSubmit: ({ email, firstName, lastName, password, subscribeToNewsletter }, {setFieldError, setSubmitting}) => {
      const data = { firstName, lastName, subscribeToNewsletter }
      updateUser({email, password, data})
        .then(() => {
          setSubmitting(false)
        })
        .catch(error => {
          console.warn("update user data error", error)
          setSubmitting(false)
        })
    },
    validationSchema: object().shape({
      email: string().email(errorMessages.emailNotValid).required(errorMessages.required),
      password: string().min(8, errorMessages.passwordTooShort).required(errorMessages.required),
    }),
  }
  return (
    <Formik {...formikProps} children={props => <UserProfile {...props} />} />
  )
}
