import React from "react"
import { Form } from "formik"
import { Button, Flex } from "theme-ui"
import CheckboxInput from "@fields/CheckboxInput"
import EmailInput from "@fields/EmailInput"
import PasswordInput from "@fields/PasswordInput"
import TextInput from "@fields/TextInput"

export default ({ dirty, isSubmitting, isValid, values, ...props }) => (
  <Flex as={Form} sx={{ flexWrap: `wrap` }}>
    <TextInput name="firstName" placeholder="Imię" />
    <TextInput name="lastName" placeholder="Nazwisko" />
    <EmailInput name="email" placeholder="Email" />
    <PasswordInput name="password" placeholder="Hasło" />
    <CheckboxInput
      name="subscribeToNewsletter"
      label="Subskrybcja newslettera"
      mt="1rem"
      sx={{ flex: "1 0 100%" }}
    />

    <Button
      disabled={!dirty || !isValid || isSubmitting}
      type="submit"
      variant="submit"
    >
      Zapisz zmiany
    </Button>
  </Flex>
)
